<template>
	<content-page>
		<template v-slot:content>
			<render-content :id="contentId"></render-content>
		</template>
	</content-page>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
import ContentPage from "@c/ui/ContentPage";
export default {
	name: "ThankYou",
	props: {
		id: {type: String},
		content: {type: String}
	},
	computed: {
		survey(){
			return this.$store.state.surveys.data[this.id];
		}, 
		contentId(){
			if( this.content && this.mwutils.isId(this.content) ){
				return this.content;
			}
			if( !this.id || !this.survey ){
				return "thankyou";
			}
			if( !this.survey.thankyou ){
				return "thankyou";
			}
			return this.survey.thankyou
			// else{

			// 	return "id";
			// }
		},
	},
	components: {
		RenderContent,
		ContentPage,
	},
};
</script>
